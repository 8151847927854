import React, { useEffect, useState } from 'react';
import BaseDashboard from './BaseDashboard';

import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { useAuth0 } from '@auth0/auth0-react';
import { isMobile } from 'react-device-detect';

const initializeDatalayer = (() => {
    let isFirstLoad = true;
    return (user) => {
        if (!user || !isFirstLoad) return;
        isFirstLoad = false;
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push(
            {
                event: "login",
                userProperties: {
                    userId: user?._id,
                    visitor_type: "logged_in",
                    customer_id: user?._id,
                    customer_email: user?.email,
                    customer_first_name: user?.name,
                    customer_phone: user?.telephone,
                    customer_role: user?.role,
                },
                deviceType: isMobile ? 'mobile' : 'desktop',
                timeStamp: new Date().getTime(),
                currency: null
            });

        const gtmScript = document.createElement('script');
        gtmScript.text = `
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
              });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-5H8F42HC');
          `;
        document.body.appendChild(gtmScript);
    }
})();

const BlackFridayCountdown = () => {
    const [timeLeft, setTimeLeft] = useState({});

    useEffect(() => {
        const calculateTimeLeft = () => {
            const now = new Date();
            const blackFriday = new Date(2025, 0, 6); 
            const difference = blackFriday - now;

            if (difference > 0) {
                setTimeLeft({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                });
            }
        };

        const timer = setInterval(calculateTimeLeft, 1000); 
        return () => clearInterval(timer);
    }, []);

    return (
        <a href="https://fullcomm.app/problems-incidents" style={{ textDecoration: 'none' }}>
            <div className="black-friday-countdown" style={{
                backgroundColor: 'rgb(0, 214, 207)',
                color: 'black',
                padding: '10px',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif',
                position: 'sticky',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1002
            }}>
                <span>Plantão Check: Durante o período de 19/12 até 05/01 estaremos focados na resolução de bugs e problemas. Clique aqui para registrar sua solicitação!</span>
            </div>
        </a>
    );
};

const baseDashboard = (data) => {
    const ComponentBaseDashboard = (props) => {
        const { Component, permissions = null, platform = null, action = null } = data;
        const { isAuthenticated } = useAuth0();

        const [showCountdown, setShowCountdown] = useState(false);

        useEffect(() => {
            const { getUser } = props;
            getUser();
        }, []);

        useEffect(() => {
            const { history, authorized, user } = props;
            if (props.authorized !== undefined) {
                initializeDatalayer(user);

                const lastPath = localStorage.getItem('lastPath');
                localStorage.removeItem('lastPath');
                const shopDetails = user?.customer?.find(
                    (e) => e._id === localStorage.getItem('shop')
                );
                if (!authorized && !isAuthenticated) {
                    localStorage.setItem('lastPath', history.location.pathname);
                    return history.replace('/');
                }
                if (!permissions?.includes(user?.role) && permissions !== null)
                    return history.replace('/unauthorized');
                if (user.role === 'customer') {
                    if (action && user.permissionCustomer?.find(e => e.customer === localStorage.getItem('shop'))?.actions?.includes(action)) {
                    } else if (action) {
                        return history.replace('/unauthorized');
                    }
                } else if (user.role === 'admin') {
                    if (action && !user.permissionAdmin.includes(action)) {
                        return history.replace('/unauthorized');
                    }
                }
                if (shopDetails && platform) {
                    if (
                        !shopDetails.platform?.some((element) => platform.includes(element))
                    )
                        return history.replace('/unauthorized');
                }
                if (lastPath) {
                    return history.replace(lastPath);
                }
            }
        }, [props.authorized, props.user, props.history]);

        useEffect(() => {
            const now = new Date();
            const blackFriday = new Date(2025, 0, 6);
            const daysUntilBlackFriday = Math.ceil((blackFriday - now) / (1000 * 60 * 60 * 24));

            setShowCountdown(daysUntilBlackFriday > 0);
        }, []);

        return (
            <>
                {showCountdown && <BlackFridayCountdown />}
                <BaseDashboard platform={platform}>
                    <Component {...props} platform={platform} />
                </BaseDashboard>
            </>
        );
    };

    const mapStateToProps = (state) => ({
        authorized: state.auth.authorized,
        user: state.auth.user,
    });

    return connect(mapStateToProps, actions)(ComponentBaseDashboard);
};

export default baseDashboard;
